import React from 'react'
import FeedbackSlider from '../DistanceLearning/FeedbackSlider'
import AnnouncementSlider from './AnnouncementSlider'

const index = () => {
  return (
    <div className=''>
      <AnnouncementSlider />
    </div>
  )
}

export default index
