import axios from 'axios'
import dynamic from 'next/dynamic'
import React from 'react'
import Announcement from '../components/Announcement'
import AboutArea from '../components/DistanceLearning/AboutArea'
import CoursesArea from '../components/DistanceLearning/CoursesArea'
import FeedbackSlider from '../components/DistanceLearning/FeedbackSlider'
import MainBanner from '../components/DistanceLearning/MainBanner'
import Footer from '../components/_App/Footer'
import Navbar from '../components/_App/Navbar'
import { GetCourseEnd } from '../utils/EndPoints'

const FunFactsThree = dynamic(() =>
  import('../components/Common/FunFactsThree')
)
// This gets called on every request
export async function getServerSideProps() {
  // Fetch data from external API

  const res = await axios.get(GetCourseEnd)

  const data = res.data

  // Pass data to the page via props
  return { props: { data } }
}

const Index4 = ({ data }) => {
  const courses = data

  return (
    <React.Fragment>
      <Navbar />
      <MainBanner />
      <Announcement />

      {/* <Features /> */}
      <FeedbackSlider />

      <CoursesArea courses={courses} />

      <AboutArea />
      {/* <PopularCourses /> */}
      <FunFactsThree />


      {/* <CourseAdvisor /> */}
      {/* <GetPremiumAccess /> */}
      {/* <PartnerStyleTwo /> */}
      {/* <LatestNews /> */}
      {/* <SubscribeForm /> */}
      <Footer />
    </React.Fragment>
  )
}

export default Index4
